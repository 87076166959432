import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CLHbid = makeShortcode("CLHbid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Listing with Professional Realtors`}</h1>
    <p>{`The value of a Realtor tuned into a specific market sector speaks for itself. For a Seller, using a Realtor means listing the property for sale at a set price (the ‘List Price’). By using a List Price the Seller sets an upper limit at the outset which in itself is backwards (as opposed to letting the market set the upper limit).`}</p>
    <p>{`To complicate matters, most Buyers view the list price as a number in excess of what the Seller actually thinks the land is worth. The list price then becomes a negative cap set by the Seller while signaling to the Buyer that the value of the land is likely some amount below the list price. At a time when the Seller should be in control, the Seller shows their hand first — often a sign of weakness in negotiating.`}</p>
    <p>{`Determining a ‘list price’ for agricultural land is problematic to say the least. At best, the process can be somewhat of a guess. Get it wrong on the high side and it may sit on the market for years. Get it wrong on the low side and the dairy farm sells during the night within hours of listing (arguably for well below market value).`}</p>
    <p>{`When dealing with agricultural land, complex variables, not applicable to residential or commercial land, such as ‘assemblage’ and ‘market concentration’ come into play. The phenomenon of ‘assemblage’ is one in which area producers covet land that’s close to their existing farm, sometimes paying well above what many would think a property should be listed for in the first place.`}</p>
    <p>{`Forty years ago, with most farms being small in size, factors such as economies of scale and market concentration were non-issues. However, the trend to fewer and larger land holdings has changed things. Today, if a producer has forty quarters in an area, adding one quarter across the fence adds little to fixed costs. To further complicate matters, the physical qualities of agricultural land is not nearly as significant a factor in ascertaining value as it once was. Advancements in farming practices have meant the productivity differential between poor land vis à vis higher quality land have been greatly diminished.`}</p>
    <p>{`One of the drawbacks with setting a list price is our human nature. It is often said 80% of farmers sell on a down market. When the market is going up they tend to think it will continue (until it suddenly reverses). The same mentality is true when land is listed for what is perceived as an inflated number. As the list price is reduced over time many believe the trend down will continue. The result being there is a built-in bias for the listing price to drop just because potential Buyers wait on the sideline in expectation it will drop. Buyers are also very conscious of not wanting to look unwise in the eyes of their neighbours. A parcel of land can sell by auction well over what would be considered the appraised value, and no one questions the logic of the Buyer. Yet back up the clock and list that same property at what it just sold for, and most buyers aren’t willing to make an offer for fear of looking foolish for over paying.`}</p>
    <p>{`Another drawback of the listing process is that there is no ability to plan ahead, not knowing when a sale may occur, or perhaps worse yet, what to do if only a portion of the farm or ranch offered for sale finds a Buyer. For a Seller, holding on to only a percentage of land is fraught with carrying costs and economies of scale issues. To alleviate some of these issues, often the listing will require the farm or ranch to be sold as one parcel. This is in itself hugely problematic, in that it excludes those Buyers wanting just one parcel and who are willing to pay a premium for it.`}</p>
    <h2>{`CLHbid.com eliminates these challenges`}</h2>
    <p>{`With our online tender platform, a firm sale date is set, `}<CLHbid mdxType="CLHbid" />{` sets a starting bid and the “what-if” question is diminished. The Seller can plan ahead knowing the date of the bidding. None of this “I wonder if I have to buy inputs for spring” or rent out the lake cabin until the land sells. Another advantage is that the Buyer begins the bidding process at a fair value set by the Seller and `}<CLHbid mdxType="CLHbid" />{`.`}</p>
    <p>{`The Seller is in control of the process. Unlike a listing, auction prices move upwards, not downward from the list price. Plus, the `}<CLHbid mdxType="CLHbid" />{` format caters to all Buyers whether interested in the entire farm or single parcels. At `}<CLHbid mdxType="CLHbid" />{`, we have witnessed first-hand where a large ranch or farm will not sell when listed by a realtor as one package, yet when we break up the parcels into packages we engage multiple Buyers, either not having the financing to buy the entire package or more likely only wanting certain parcels that fit into their existing operation. The likelihood of money being left on the table is greatly diminished with this process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      